import { makeStyles } from '@material-ui/styles';

const styles = (theme) => ({
  root: {
    ...theme.mixins.modal.dialog.root,
    overflowY: 'auto',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hint: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.neutral.main,
  },
});

export const useImportSOVStyles = makeStyles(styles, { name: 'ImportSOV' });
