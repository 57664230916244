import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { useDispatch } from 'react-redux';

import { Box, CircularProgress } from '@material-ui/core';

import { BaseTableCell, BaseTableHeaderCell, Button } from 'components';

import { useSubmitSOVMappings } from 'lib/quoteBind';
import { hideModal } from 'stores';
import * as utils from 'utils';

import { useImportSOVContext } from '../ImportSOV.context';

export const PREVIEW_SOV_STEP = 'preview-sov-step';

const HEADER_HEIGHT = 55;

const TableCell = ({ cellData }: { cellData: string }) => (
  <BaseTableCell cellData={cellData} style={{ textOverflow: 'unset', whiteSpace: 'unset' }} />
);

export const PreviewSOV = ({ onComplete }: { onComplete: (building: unknown) => void }) => {
  const { result } = useImportSOVContext();
  const { mutateAsync: submitSOVMappings, isLoading: isSubmitting } = useSubmitSOVMappings();
  const dispatch = useDispatch();

  const data = Object.fromEntries(result.map(({ sovHeader, excelHeader }) => [sovHeader, excelHeader]));

  const columns = result.map(({ sovHeader }) => ({
    title: sovHeader,
    dataKey: sovHeader,
  }));

  const handleSubmit = async () => {
    await submitSOVMappings(result);
    onComplete([
      {
        streetAddress: '123 William Street',
        zip: '10038',
        inOuterBanksNc: 'Yes',
        totalInsurableValue: '10000',
        occupancyType: 'Boatyard',
        constructionType: 'Joisted Masonry',
        roofType: 'Shingle',
        roofAge: '3-4 Years',
        yearBuilt: '1994-2000',
        lossHistory: '0 Losses',
      },
      {
        streetAddress: '124 William Street',
        zip: '07109',
        inOuterBanksNc: 'Yes',
        totalInsurableValue: '10000',
        occupancyType: 'Boatyard',
        constructionType: 'Joisted Masonry',
        roofType: 'Shingle',
        roofAge: '3-4 Years',
        yearBuilt: '1994-2000',
        lossHistory: '0 Losses',
      },
      {
        streetAddress: '125 William Street',
        zip: '10036',
        inOuterBanksNc: 'Yes',
        totalInsurableValue: '10000',
        occupancyType: 'Boatyard',
        constructionType: 'Joisted Masonry',
        roofType: 'Shingle',
        roofAge: '3-4 Years',
        yearBuilt: '1994-2000',
        lossHistory: '0 Losses',
      },
    ]);
    dispatch(hideModal('IMPORT_SOV'));
  };

  const cellRenderer = () => <BaseTableCell cellData="Excel Header" style={{ textOverflow: 'unset', whiteSpace: 'unset' }} />;

  return (
    <Box
      style={{
        display: 'flex',
        flex: '1 1 auto',
        margin: 'auto',
        height: 'auto',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <AutoResizer height={HEADER_HEIGHT * 2 + 20}>
        {({ width, height }) => (
          <BaseTable
            fixed
            data={[data]}
            width={width}
            height={height}
            rowKey="sovHeader"
            headerHeight={HEADER_HEIGHT}
            components={{ TableCell, TableHeaderCell: BaseTableHeaderCell }}
          >
            <Column
              key="headerType"
              width={100}
              align="center"
              title="SOV Header"
              frozen={Column.FrozenDirection.LEFT}
              cellRenderer={cellRenderer}
            />
            {columns.map((column) => (
              <Column key={column.dataKey} width={150} align="center" dataKey={column.dataKey} title={column.title} />
            ))}
          </BaseTable>
        )}
      </AutoResizer>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        {isSubmitting ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="36px" width="60px">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Button variant="contained" color="primary" text={utils.string.t('app.submit')} onClick={handleSubmit} />
        )}
      </Box>
    </Box>
  );
};
