import { useState } from 'react';

import { Box, CircularProgress, Typography } from '@material-ui/core';

import { Button, FormFileDrop } from 'components';

import { useUploadSOVFile } from 'lib/quoteBind';
import * as utils from 'utils';

import { useImportSOVContext } from '../ImportSOV.context';
import { useImportSOVStyles } from '../ImportSOV.styles';

export const UPLOAD_SOV_STEP = 'upload-sov-step';

interface IUploadSOVProps {
  completeStep: VoidFunction;
}

export const UploadSOV = ({ completeStep }: IUploadSOVProps) => {
  const [file, setFile] = useState<File | null>(null);
  const { setMappedData, setExcelColumns } = useImportSOVContext();
  const classes = useImportSOVStyles();

  const { mutateAsync: uploadSOV, isLoading } = useUploadSOVFile();

  const handleUpload = async () => {
    setMappedData([]);
    setExcelColumns([]);
    const data = await uploadSOV(file);
    setMappedData(data.mappedData);
    setExcelColumns(data.excelColumns);
    completeStep();
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography className={classes.hint}>{utils.string.t('products.importSOV.step1.hint')}</Typography>
      <FormFileDrop value={file} onlyOneFile onChange={setFile} accept={['.xlsx', '.xls', '.csv']} fullWidth />
      <Box className={classes.actions}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="36px" width="60px">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Button onClick={handleUpload} variant="contained" color="primary" text={utils.string.t('next')} disabled={!file} />
        )}
      </Box>
    </div>
  );
};
