import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

export const useSubmitSOVMappings = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useUpdateIssue',
    };

    dispatch(enqueueNotification('notification.updateIssue.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useMutation({
    mutationFn: async (
      data: {
        sovHeader: string;
        excelHeader: string;
      }[]
    ) => {
      await new Promise((resolve) => {
        setTimeout(() => resolve(data), Math.random() * 1000 + 1000);
      });

      return data;
    },
    onError: handleError,
  });
};
