import { startCase } from 'lodash';
import * as Yup from 'yup';

import { Box, LinearProgress } from '@material-ui/core';

import { Form, Warning } from 'components';

import { useUploadSOVMappings } from 'lib/quoteBind';
import * as utils from 'utils';

import { useImportSOVContext } from '../ImportSOV.context';

export const MAP_HEADERS_SOV_STEP = 'map-headers-sov-step';

export const MapHeadersSOV = ({ completeStep }: { completeStep: VoidFunction }) => {
  const { mappedData, excelColumns, setResult } = useImportSOVContext();
  const { mutateAsync: uploadSOVMappings, isLoading } = useUploadSOVMappings();

  const fields = mappedData.map(({ header, value, required }) => ({
    name: header,
    type: 'select',
    gridSize: { xs: 12, sm: 6, md: 4 },
    label: startCase(header),
    value: value || '__placeholder__',
    options: [
      {
        value: '__placeholder__',
        label: 'Select column',
        placeholder: true,
      },
      ...excelColumns.map((col) => ({ value: col, label: startCase(col) })),
    ],
    validation: required
      ? Yup.string()
          .transform((v) => (v === '__placeholder__' ? undefined : v))
          .required(utils.string.t('products.importSOV.step2.required'))
      : undefined,
  }));

  const handleSubmit = async (values) => {
    const data = await uploadSOVMappings({
      mappedData: Object.entries(values).map(([header, value]) => ({ header, value: value as string })),
    });

    setResult(data.result);
    completeStep();
  };

  if (mappedData.length === 0) {
    return <Warning text="No data to map" type="error" size="medium" align="left" icon />;
  }

  return (
    <Box display="flex" flexDirection="column" width={1}>
      {isLoading && (
        <Box>
          <LinearProgress />
        </Box>
      )}

      <Form
        id="map-headers-sov"
        fields={[fields]}
        defaultValues={utils.form.getInitialValues([fields])}
        validationSchema={utils.form.getValidationSchema(fields)}
        actions={[
          {
            name: 'submit',
            label: utils.string.t('app.continue'),
            handler: handleSubmit,
          },
        ]}
      />
    </Box>
  );
};
