import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

interface IMappedData {
  header: string;
  value: string | null;
  required: boolean;
}

interface IResult {
  sovHeader: string;
  excelHeader: string;
}

interface IImportSOVContext {
  mappedData: IMappedData[];
  excelColumns: string[];
  result: IResult[];
  setMappedData: Dispatch<SetStateAction<IMappedData[]>>;
  setExcelColumns: Dispatch<SetStateAction<string[]>>;
  setResult: Dispatch<SetStateAction<IResult[]>>;
}

export const ImportSOVContext = createContext<IImportSOVContext>({
  mappedData: [],
  excelColumns: [],
  result: [],
  setMappedData: () => null,
  setExcelColumns: () => null,
  setResult: () => null,
});

export const ImportSOVContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [mappedData, setMappedData] = useState<IMappedData[]>([]);
  const [excelColumns, setExcelColumns] = useState<string[]>([]);
  const [result, setResult] = useState<IResult[]>([]);
  const value = useMemo(
    () => ({
      mappedData,
      excelColumns,
      result,
      setMappedData,
      setExcelColumns,
      setResult,
    }),
    [mappedData, excelColumns, result, setMappedData, setExcelColumns, setResult]
  );

  return <ImportSOVContext.Provider value={value}>{children}</ImportSOVContext.Provider>;
};

export const useImportSOVContext = () => useContext(ImportSOVContext);
