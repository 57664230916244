import { useState } from 'react';

import { Accordion } from 'components';

import * as utils from 'utils';

import { ImportSOVContextProvider } from './ImportSOV.context';
import { MAP_HEADERS_SOV_STEP, MapHeadersSOV, PREVIEW_SOV_STEP, PreviewSOV, UPLOAD_SOV_STEP, UploadSOV } from './steps';

import { useImportSOVStyles } from './ImportSOV.styles';

type TStep = typeof UPLOAD_SOV_STEP | typeof PREVIEW_SOV_STEP | typeof MAP_HEADERS_SOV_STEP;

interface IImportSOVProps {
  onComplete: (building: unknown) => void;
}

export const ImportSOV = ({ onComplete }: IImportSOVProps) => {
  const classes = useImportSOVStyles();
  const [activeStep, setActiveStep] = useState<TStep>(UPLOAD_SOV_STEP);
  const [focusedSteps, setFocusedSteps] = useState<TStep[]>([UPLOAD_SOV_STEP]);

  const handleUploadStepComplete = () => {
    setFocusedSteps([...focusedSteps, MAP_HEADERS_SOV_STEP]);
    setActiveStep(MAP_HEADERS_SOV_STEP);
  };

  const handleMapHeadersStepComplete = () => {
    setFocusedSteps([...focusedSteps, PREVIEW_SOV_STEP]);
    setActiveStep(PREVIEW_SOV_STEP);
  };

  const steps = {
    [UPLOAD_SOV_STEP]: {
      component: <UploadSOV completeStep={handleUploadStepComplete} />,
      title: utils.string.html('products.importSOV.step1.title'),
    },
    [MAP_HEADERS_SOV_STEP]: {
      component: <MapHeadersSOV completeStep={handleMapHeadersStepComplete} />,
      title: utils.string.html('products.importSOV.step2.title'),
    },
    [PREVIEW_SOV_STEP]: {
      component: <PreviewSOV onComplete={onComplete} />,
      title: utils.string.html('products.importSOV.step3.title'),
    },
  };

  return (
    <ImportSOVContextProvider>
      <div className={classes.root}>
        {Object.entries(steps).map(([step, { component, title }]) => (
          <Accordion
            key={step}
            title={title}
            expanded={step === activeStep}
            onChange={() => focusedSteps.includes(step as TStep) && setActiveStep(step as TStep)}
            boxed
            density="comfortable"
          >
            {component}
          </Accordion>
        ))}
      </div>
    </ImportSOVContextProvider>
  );
};
