import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// mui
import { Box, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import { Button, ErrorMessage } from 'components';
import { EditImage } from 'components/EditImage/EditImage';

// app
import * as utils from 'utils';

import styles from './FormFileDrop.styles';

export const FormFileDrop = ({
  name,
  value,
  label,
  error,
  hint,
  control,
  onChange,
  dragLabel,
  showUploadPreview,
  showEditImage,
  fileNameLength,
  onlyOneFile,
  handleUpdateFile,
  onRemoveCallback,
  fullWidth,
  accept,
}) => {
  const classes = makeStyles(styles, { name: 'FormFileDrop' })();
  const [uploadedFileList, setUploadedFileList] = useState([]);

  const onDrop = useCallback(
    (files) => {
      setUploadedFileList((state) => (onlyOneFile ? [files[0]] : [...state, ...files]));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    if (uploadedFileList && uploadedFileList.length > 0) {
      onChange(uploadedFileList);
    }
  }, [uploadedFileList, onChange]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  const onRemove = (index) => {
    uploadedFileList.splice(index, 1);
    setUploadedFileList([...uploadedFileList]);
    onRemoveCallback && onRemoveCallback();
  };

  const fetchImage = useCallback(
    async (imageURL) => {
      const response = await fetch(imageURL);
      const blob = await response.blob();
      const name = imageURL.split('/').pop();

      const file = new File([blob], name, { type: 'image/png' });

      onDrop([file]);
    },
    [onDrop]
  );

  useEffect(() => {
    if (value && showEditImage) {
      fetchImage(value);
    }
  }, [fetchImage, showEditImage, value]);

  const handlePaste = (e) => {
    if (showEditImage) {
      const { items } = e.clipboardData;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile();
          const filename = `${uuidv4()}.png`;
          const file = new File([blob], filename, { type: 'image/png' });

          onDrop([file]);
        }
      }
    }
  };

  const UploadedFileList = () => (
    <ul className={classes.uploadedFileList}>
      {uploadedFileList.map((uploadedFile, index) => {
        const isImage = uploadedFile.type.includes('image');
        const filePreview = isImage ? URL.createObjectURL(uploadedFile) : null;

        return (
          <li key={index} className={classes.uploadedFileListItem}>
            <div className={classes.uploadedFileListLabel}>
              {isImage && filePreview ? (
                <>
                  <CheckCircleIcon className={classes.successIcon} />
                  <img className={classes.filePreview} src={filePreview} alt="file preview" />
                </>
              ) : (
                <>
                  <CheckCircleIcon className={classes.successIcon} />
                  <p>{utils.file.truncate(uploadedFile.name, fileNameLength)}</p>
                </>
              )}
            </div>
            <Box justifyContent="right">
              <Button icon={HighlightOffRoundedIcon} size="xsmall" variant="text" color="primary" onClick={() => onRemove(index)} />
            </Box>
          </li>
        );
      })}
    </ul>
  );

  const dragInstruction = (
    <>
      <CloudUploadIcon className={classes.uploadIcon} />
      <p className={classes.dragFile}>{dragLabel || utils.string.t('form.dragDrop.dragFileHere')}</p>
      <p className={classes.or}>{utils.string.t('app.or')}</p>
      <p className={classes.browseFile}>{utils.string.t('form.dragDrop.browseFile')}</p>
    </>
  );

  return (
    <div className={classes.rootUpload}>
      <div className={classes.root}>
        <label className={classes.formLabel}>{label}</label>
        {control ? (
          <Controller
            control={control}
            name={name}
            disabled
            render={() => (
              <div className={classnames(classes.dragArea, fullWidth && classes.fullWidth)} {...getRootProps()} data-form-type="file">
                <input {...getInputProps()} />
                {dragInstruction}
              </div>
            )}
          />
        ) : (
          <div className={classnames(classes.dragArea, fullWidth && classes.fullWidth)} {...getRootProps()} data-form-type="file">
            <input {...getInputProps()} />
            {dragInstruction}
          </div>
        )}
        {showUploadPreview && uploadedFileList.length > 0 && <UploadedFileList />}

        <ErrorMessage error={error} hint={hint} />
      </div>
      {showEditImage && (
        <div className={classes.editImage}>
          <EditImage image={uploadedFileList} handleUpdateFile={handleUpdateFile} handlePaste={handlePaste} />
        </div>
      )}
    </div>
  );
};

FormFileDrop.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  dragLabel: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.object,
  showUploadPreview: PropTypes.bool,
  showEditImage: PropTypes.bool,
  fileNameLength: PropTypes.number,
  onlyOneFile: PropTypes.bool,
  handleUpdateFile: PropTypes?.func,
  onRemoveCallback: PropTypes?.func,
  accept: PropTypes.arrayOf(PropTypes.string),
  fullWidth: PropTypes.bool,
};

FormFileDrop.defaultProps = {
  showUploadPreview: true,
  showEditImage: false,
  fileNameLength: 48,
  onlyOneFile: false,
  fullWidth: false,
};

export default FormFileDrop;
