import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

export const useUploadSOVFile = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useUpdateIssue',
    };

    dispatch(enqueueNotification('notification.updateIssue.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useMutation({
    mutationFn: async (file: File) => {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(() => resolve(file), Math.random() * 1000 + 1000));

      return {
        mappedData: [
          {
            header: 'Address',
            value: Math.random() > 0.5 ? 'Excel Address' : null,
            required: true,
          },
          {
            header: 'State',
            value: Math.random() > 0.5 ? 'Excel State' : null,
            required: false,
          },
          {
            header: 'City',
            value: Math.random() > 0.5 ? 'Excel City' : null,
            required: false,
          },
          {
            header: 'Zip',
            value: Math.random() > 0.5 ? 'Excel Zip' : null,
            required: true,
          },
          {
            header: 'Year Built',
            value: Math.random() > 0.5 ? 'Excel Year Built' : null,
            required: true,
          },
        ],
        excelColumns: ['Excel Address', 'Excel State', 'Excel City', 'Excel Zip', 'Excel Year Built'],
      };
    },
    onError: handleError,
  });
};
