import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { enqueueNotification } from 'stores';
import * as utils from 'utils';
import { logoutAuth0 } from 'utils/auth0/auth0';

interface IUploadSOVMappingsProps {
  mappedData: { header: string; value: string | null }[];
}

export const useUploadSOVMappings = () => {
  const dispatch = useDispatch();

  const handleError = (err) => {
    const errorParams = {
      file: 'quoteBind.mutations.useUpdateIssue',
    };

    dispatch(enqueueNotification('notification.updateIssue.fail', 'error'));

    utils.api.handleError(err, errorParams);
    utils.api.handleUnauthorized(err, null, logoutAuth0);
  };

  return useMutation({
    mutationFn: async ({ mappedData }: IUploadSOVMappingsProps) => {
      const values = mappedData.map(({ header, value }) => ({
        sovHeader: header,
        excelHeader: value === '__placeholder__' ? null : value,
      }));

      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(() => resolve(values), Math.random() * 1000 + 1000));

      return { result: values.map(({ sovHeader, excelHeader }) => ({ sovHeader, excelHeader: excelHeader ?? '-' })) };
    },
    onError: handleError,
  });
};
