import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
// app
import {
  FormAutocomplete,
  FormAutocompleteMui,
  FormAutocompleteMuiAsync,
  FormCheckbox,
  FormDate,
  FormHidden,
  FormRadio,
  FormSelect,
  FormText,
  FormToggle,
  FormLabel,
} from 'components';
import * as utils from 'utils';

AddRiskFormFieldView.propTypes = {
  field: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  checkboxProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export function AddRiskFormFieldView({ field, control, checkboxProps, selectProps, setValue }) {
  const fieldMap = {
    text: FormText,
    number: FormText,
    datepicker: FormDate,
    timepicker: FormDate,
    select: FormSelect,
    selectAsync: FormSelect,
    autocompletemuiAsync: FormAutocompleteMuiAsync,
    autocomplete: FormAutocomplete,
    autocompletemui: FormAutocompleteMui,
    radio: FormRadio,
    checkbox: FormCheckbox,
    toggle: FormToggle,
    toggle_group: FormToggle,
    hidden: FormHidden,
    label: FormLabel,
  };

  const FieldComponent = fieldMap[field.type];

  const dependants = field?.dependants;

  const dependantByValues = dependants?.map((value) => value.split(':')[1]);

  const dependantBy = dependantByValues ? utils.risk.parseWatchFields(dependantByValues) : [];
  const watchFields = field.child
    ? dependantBy?.calculationFields?.flatMap((item) => `${item.field}[${field.index}].${item.child}`)
    : dependantBy?.watchFields;
  const dependentFieldsValues = useWatch({
    control,
    name: watchFields,
  });
  // abort
  if (!FieldComponent) return null;

  return (
    <FieldComponent
      key={field.name}
      control={control}
      setValue={setValue}
      {...checkboxProps}
      {...selectProps}
      {...field}
      disabled={field.disabled || field.readonly}
      dependentFieldsValues={dependentFieldsValues}
      dependentBy={watchFields}
    />
  );
}
